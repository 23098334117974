import { Routes } from '@angular/router';
import { authGuard } from '@app/guards/auth/auth.guard';

export const organizationsRoutes: Routes = [
    {
        path: '',
        canActivate: [authGuard],
        data: { breadcrumb: 'Organizaciones' },
        children: [
            {
                path: '',
                loadComponent: () =>
                    import(
                        '@app/pages/user-management/organizations/organization-list/organization.component'
                    ).then((m) => m.OrganizationComponent),
            },
            {
                path: ':id',
                loadComponent: () =>
                    import(
                        '@app/pages/user-management/organizations/organization-detail/organization-detail.component'
                    ).then((m) => m.OrganizationDetailComponent),
                data: { breadcrumb: '%nombre%' },
            },
        ],
    },
];
