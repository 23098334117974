<nav class="fr-nav" [ngClass]="{ collapsed: navbarCollapsed }">
    <ul>
        @for (navbar_item of navbar_items; track $index) {
            <app-navbar-item
                [navbar_item]="navbar_item"
                [navbarCollapsed]="navbarCollapsed"
            />
        }
    </ul>
</nav>
