import { Component, Input } from '@angular/core';
import { NavbarItemComponent } from './navbar-item/navbar-item.component';
import { CommonModule } from '@angular/common';
import { navMenu } from '@app/consts/nav-menu';

type NavChildren = {
    name: string;
    icon?: string;
    url: string;
};

export interface NavbarItem {
    name: string;
    icon: string;
    url: string;
    children?: NavChildren[];
}

@Component({
    selector: 'app-navbar',
    standalone: true,
    imports: [CommonModule, NavbarItemComponent],
    templateUrl: './navbar.component.html',
    styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
    @Input() navbarCollapsed: boolean = false;

    navbar_items: NavbarItem[] = navMenu;
}
