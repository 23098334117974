<p-toast />
<div class="main-body">
    @if (!isLoginPage) {
        <header>
            <app-header
                (onHamburgerClick)="handleHamburgerClick()"
                [isCollapsed]="navbarCollapsed"
            />
        </header>
    }

    <div class="main-content" [ngClass]="{ isLoginPage: isLoginPage }">
        @if (!isLoginPage) {
            <aside>
                <app-navbar [navbarCollapsed]="navbarCollapsed" />
            </aside>
        }

        <main [ngClass]="{ collapsed: navbarCollapsed }">
            <router-outlet />
        </main>
    </div>
</div>
