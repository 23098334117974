import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private baseUrl = environment.baseUrl;
    private refreshingAccessToken: boolean = false;
    private accessTokenRefreshed: BehaviorSubject<string | null> =
        new BehaviorSubject<string | null>(null);

    constructor(private http: HttpClient) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        req = this.addAuthenticationToken(req);

        return next.handle(req).pipe(
            catchError((error) => {
                if (
                    error instanceof HttpErrorResponse &&
                    error.status === 401
                ) {
                    return this.handle401Error(req, next);
                } else {
                    return throwError(() => error);
                }
            })
        );
    }

    private addAuthenticationToken(
        req: HttpRequest<any>,
        token: string | null = null
    ): HttpRequest<any> {
        token = token ? token : localStorage.getItem('token');
        const excludedUrls = [
            `${this.baseUrl}/token/refresh`,
            `${this.baseUrl}/signin`,
            `${this.baseUrl}/token/invalidate`,
        ];

        const isExcluded = excludedUrls.find((url) => req.url.includes(url));

        if (token && !isExcluded) {
            return req.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
        }
        return req;
    }

    private handle401Error(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!this.refreshingAccessToken) {
            this.refreshingAccessToken = true;
            this.accessTokenRefreshed.next(null); // Clear the current token state

            return this.refreshToken().pipe(
                switchMap((newToken) => {
                    localStorage.setItem('token', newToken);
                    this.accessTokenRefreshed.next(newToken); // Emit the new token
                    this.refreshingAccessToken = false;

                    // Retry the failed request with the new token
                    return next.handle(
                        this.addAuthenticationToken(req, newToken)
                    );
                }),
                catchError((err) => {
                    this.refreshingAccessToken = false;
                    return throwError(() => err);
                })
            );
        } else {
            // If refreshing is already in progress, wait for the token to be refreshed
            return this.accessTokenRefreshed.pipe(
                filter((token) => token !== null), // Only proceed when the token is not null
                take(1), // Take the first valid token and complete
                switchMap((token) => {
                    return next.handle(this.addAuthenticationToken(req, token));
                })
            );
        }
    }

    private refreshToken(): Observable<any> {
        return this.http
            .post(
                `${this.baseUrl}/token/refresh`,
                {},
                { withCredentials: true }
            )
            .pipe(
                switchMap((response: any) => {
                    return of(response.token);
                })
            );
    }
}
