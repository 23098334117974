import { Routes } from '@angular/router';
import { reportsRoutes } from '@app/routing/routes/reports.routes';
import { usersManagementRoutes } from './routing/routes/users_management.routes';
import { organizationsRoutes } from './routing/routes/organizations.routes';
import { accountsRoutes } from './routing/routes/accounts.routes';
import { freeTrialRoute } from './routing/routes/referer.routes';
import { authGuard } from '@app/guards/auth/auth.guard';

export const routes: Routes = [
    {
        path: 'login',
        loadComponent: () =>
            import('@app/pages/login/login.component').then(
                (m) => m.LoginComponent
            ),
    },
    {
        path: '',
        canActivate: [authGuard],
        loadComponent: () =>
            import('@app/pages/home/home.component').then(
                (m) => m.HomeComponent
            ),
    },
    {
        path: 'reports',
        children: reportsRoutes,
    },
    {
        path: 'users',
        children: usersManagementRoutes,
    },
    {
        path: 'organizations',
        children: organizationsRoutes,
    },
    {
        path: 'accounts',
        children: accountsRoutes,
    },
    {
        path: 'free_trial_referer',
        children: freeTrialRoute,
    },
];
