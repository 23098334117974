import { Routes } from '@angular/router';
import { authGuard } from '@app/guards/auth/auth.guard';

export const reportsRoutes: Routes = [
    {
        path: '',
        canActivate: [authGuard],
        data: { breadcrumb: 'Informes' },
        children: [
            {
                path: '',
                loadComponent: () =>
                    import(
                        '@app/pages/reports/list/reports-list.component'
                    ).then((m) => m.ReportsListComponent),
            },
            {
                path: 'create',
                loadComponent: () =>
                    import(
                        '@app/pages/reports/create/report-create.component'
                    ).then((m) => m.ReportCreateComponent),
                data: { breadcrumb: 'Crear' },
            },
            {
                path: ':id',
                loadComponent: () =>
                    import(
                        '@app/pages/reports/detail/report-detail.component'
                    ).then((m) => m.ReportDetailComponent),
                data: { breadcrumb: '%nombre%' },
            },
            {
                path: ':id/edit',
                loadComponent: () =>
                    import(
                        '@app/pages/reports/edit/report-edit.component'
                    ).then((m) => m.ReportEditComponent),
                data: { breadcrumb: '%nombre%' },
            },
        ],
    },
];
