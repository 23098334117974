import { NavbarItem } from '@app/components/navbar/navbar.component';

export const navMenu: NavbarItem[] = [
    {
        name: 'Dashboard',
        url: '/',
        icon: 'home',
    },
    {
        name: 'Gestión de usuarios',
        url: '#',
        icon: 'users',
        children: [
            {
                name: 'Usuarios',
                url: '/users',
            },
            {
                name: 'Cuentas',
                url: '/accounts',
            },
            {
                name: 'Organizaciones',
                url: '/organizations',
            },
        ],
    },
    {
        name: 'Gestión de productos',
        url: '#',
        icon: 'file-chart-column',
        children: [
            {
                name: 'Informes',
                url: '/reports',
            },
        ],
    },
    {
        name: 'Integración Zoho',
        url: '/zoho-integration',
        icon: 'gear-complex-code',
    },
    {
        name: 'Referrers free trial',
        url: '/free_trial_referer',
        icon: 'external-link-alt',
    },
    {
        name: 'Descargas',
        url: '/downloads',
        icon: 'download',
    },
];
