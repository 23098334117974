import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import {
    HTTP_INTERCEPTORS,
    HttpClientModule,
    provideHttpClient,
    withInterceptors,
} from '@angular/common/http';
import {
    provideAngularQuery,
    QueryClient,
} from '@tanstack/angular-query-experimental';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { AuthInterceptor } from './service/interceptor/auth.interceptor';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, withComponentInputBinding()),
        importProvidersFrom(HttpClientModule),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        provideAnimations(),
        MessageService,
        provideAngularQuery(new QueryClient()),
    ],
};
