<li
    (mouseover)="show()"
    (mouseleave)="hide()"
    [ngClass]="{ collapsed: navbarCollapsed }"
>
    <a
        class="navbar-item"
        (click)="clickNavItem($event)"
        href="{{ navbar_item.url }}"
    >
        <i class="far fa-{{ navbar_item.icon }}"></i>
        <span
            [ngClass]="{ 'd-none': !showText && navbarCollapsed }"
            class="navbar-item__text"
            >{{ navbar_item.name }}</span
        >

        @if (navbar_item.children) {
            @if (!navbarCollapsed) {
                <i
                    class="far"
                    [ngClass]="
                        navItemExpanded ? 'fa-chevron-up' : 'fa-chevron-down'
                    "
                    style="margin-left: 10px"
                ></i>
            } @else {
                <i
                    class="far fa-chevron-right"
                    style="
                        font-size: 12px !important;
                        position: absolute;
                        right: 10px;
                    "
                ></i>
            }
        }
    </a>

    @if (navbar_item.children) {
        <div
            [ngClass]="{
                'd-flex': navItemExpanded && !navbarCollapsed,
                'd-none': !showText && navbarCollapsed
            }"
            class="child-items"
        >
            @for (child of navbar_item.children; track $index) {
                <a href="{{ child.url }}">{{ child.name }}</a>
            }
        </div>
    }
</li>
