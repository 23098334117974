import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '@environments/environment';

export const authGuard: CanActivateFn = async () => {
    const router = inject(Router);
    const http = inject(HttpClient);

    // Send a request to /api/login/check to check if the user is logged in
    const res = await lastValueFrom(
        http.get<{ isAuthenticated: boolean }>(
            `${environment.baseUrl}/auth/check`
        )
    );

    if (res.isAuthenticated) {
        return true;
    } else {
        // Store the current URL in the session storage
        sessionStorage.setItem('redirectUrl', router.url);
        await router.navigate(['/login']);
        return false;
    }
};
