import { Component, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from '@app/service/auth/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent {
    logos = ['logo_foodradar_small', 'logo_foodradar'];
    @Output() onHamburgerClick = new EventEmitter<boolean>();
    @Input() isCollapsed = false;
    logoRoute: string;

    constructor(
        private authService: AuthService,
        private router: Router
    ) {
        this.logoRoute = this.logos[this.isCollapsed ? 0 : 1];
    }

    private updateLogo() {
        this.logoRoute = this.logos[this.isCollapsed ? 1 : 0];
    }

    handleHamburgerClick() {
        this.onHamburgerClick.emit(!this.isCollapsed);
        this.updateLogo();
    }

    logout() {
        console.log('Logging out');
        this.authService.logout().subscribe(() => {
            this.router.navigate(['/login']);
        });
    }
}
