import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HeaderComponent } from './components/header/header.component';
import { ToastModule } from 'primeng/toast';
import { PrimeNGConfig } from 'primeng/api';
import { primeNgEsLocale } from '@app/consts/primeng_locale.es';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        NavbarComponent,
        HeaderComponent,
        ToastModule,
        CommonModule,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent {
    title = 'foodradar-admin-front';
    navbarCollapsed = false;
    isLoginPage: boolean = true;

    constructor(
        private router: Router,
        private primeConfig: PrimeNGConfig
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.isLoginPage = event.url.includes('login'); // Oculta el sidebar en la página de inicio de sesión
                this.navbarCollapsed = event.url !== '/home';
            }
        });

        this.primeConfig.setTranslation(primeNgEsLocale);
    }

    handleHamburgerClick() {
        this.navbarCollapsed = !this.navbarCollapsed;
    }
}
