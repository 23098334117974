import { Routes } from '@angular/router';
import { authGuard } from '@app/guards/auth/auth.guard';

export const freeTrialRoute: Routes = [
    {
        path: '',
        data: { breadcrumb: 'Referers Free Trial' },
        canActivate: [authGuard],
        loadComponent: () =>
            import('@app/pages/referers/referers.component').then(
                (m) => m.ReferersComponent
            ),
    },
];
