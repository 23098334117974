import { Routes } from '@angular/router';
import { authGuard } from '@app/guards/auth/auth.guard';

export const accountsRoutes: Routes = [
    {
        path: '',
        canActivate: [authGuard],
        data: { breadcrumb: 'Cuentas' },
        children: [
            {
                path: '',
                loadComponent: () =>
                    import(
                        '@app/pages/user-management/accounts/account-list/account-list.component'
                    ).then((m) => m.AccountListComponent),
            },
            {
                path: ':id',
                loadComponent: () =>
                    import(
                        '@app/pages/user-management/accounts/account-detail/account-detail.component'
                    ).then((m) => m.AccountDetailComponent),
                data: { breadcrumb: '%nombre%' },
            },
        ],
    },
];
