import { Component, Input } from '@angular/core';
import { NavbarItem } from '../navbar.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-navbar-item',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './navbar-item.component.html',
    styleUrl: './navbar-item.component.scss',
})
export class NavbarItemComponent {
    @Input() navbar_item!: NavbarItem;
    @Input() navbarCollapsed: boolean = false;

    navItemExpanded: boolean;

    constructor() {
        this.navItemExpanded = false;
    }

    showText = false;

    clickNavItem(e: Event) {
        if (this.navbar_item.children) {
            e.preventDefault();
            this.navItemExpanded = !this.navItemExpanded;
        }
    }

    show() {
        if (this.navbarCollapsed) {
            this.showText = true;
        }
    }

    hide() {
        if (this.navbarCollapsed) {
            this.showText = false;
        }
    }
}
